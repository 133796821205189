/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
  cursor: pointer;
  text-decoration: none!important ;
}
@font-face {
  font-family: 'TT-Hoves-Regular';
  src: url('./assets/fonts/TT Hoves Regular.otf') format('opentype');
}


code {
  color: #e01a76;
}

.btn-primary, .btn-primary:hover, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle, .btn-primary.focus, .btn-primary:focus {
  color: #fff;
  background-color: #FF8888;
  border-color: #FF8888;
}
body {
  font-family: 'TT-Hoves-Regular';
}
.btn{
    padding:1rem;
    border-radius:0px;
    font-weight:600;
}
.form-control{
  border-radius: 0px;
}
label{
  text-transform: lowercase;
}
.btn-primary.disabled, .btn-primary:disabled{
  background-color: #FF8888!important;
  border-color:#FF8888!important;
}
.text-primary, .panel-title{
  color: #FF8888!important;
}
.btn-outline-primary{
  border-color:#FF8888!important;
  color: #FF8888!important;
  border-radius: 0px!important;
}
.btn-outline-success{
  border-radius: 0px!important;
}
.btn-outline-primary:hover{
  background-color: #FF8888!important;
  border-color:#FF8888!important;
  color: #fff !important;;
}
